@import '../../styles/variables';
@import '../../styles/mixins';

.cookiesBanner {
    width: calc(100% - 24px);
    max-width: 440px;
    position: fixed;
    bottom: 12px;
    right: 12px;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;
    z-index: 9999;
    box-sizing: border-box;
    @include max_rwd(480) {
        flex-direction: column;
    }
}

.imageSide {
    display: flex;
    margin-right: 16px;
    flex-shrink: 0;
    @include max_rwd(480) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        justify-content: center;
    }
    > img {
        width: 36px;
        height: auto;
    }
}

.textSide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include max_rwd(480) {
        align-items: unset;
    }
}

.closeCross {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;
    background-image: url('./i/cross.svg');
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    @include max_rwd(480) {
        display: block;
    }
}

.text {
    font-family: $Roboto;
    font-size: 14px;
    line-height: 20px;
    color: #3c3c3c;
    margin-bottom: 16px;
    a {
        text-decoration: none;
        cursor: pointer;
        color: #2990fb;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
}
